const adzone = (url) => ({
  init() {
    bergfex.contentAds.initContentAd(this.$refs['partner'], this.fallback.bind(this));
  },

  async fallback(container) {
    container.style.display = '';
    container.classList.remove('isLoading');
    const html = await this.get();

    if (!html || html === '' || html.length === 0) {
      container.style.display = 'none';
      return;
    }

    if (!bergfex.contentAds.isElementVisible(container)) {
      container.className = container.className.replaceAll('adzone', 'promotionzone');
    }

    bergfex.contentAds.hideZoneSpacer(container);

    container.innerHTML = html ?? '';
  },

  async get() {
    try {
      const response = await fetch(url);
      return await response.text();
    } catch (e) {
      return '';
    }
  },
});

export default adzone;
