import { sendTrackingImpression, sendTrackingClick } from '../track';

const track = () => ({
  init() {
    sendTrackingImpression(this.$el);
  },

  sendTracking(event) {
    sendTrackingClick(event.currentTarget);
  },
});

export default track;
