import tagmanager from '../../abstracts/tagmanager.js';

const userRatingLike = (id, tourId, liked, likesCount) => ({
  id: id,
  liked: liked,
  likesCount: likesCount,
  inProgress: false,
  apiUrl: '/presentation/api/tours/' + tourId + '/ratings/' + id + '/like',
  async toggleLike() {
    if (this.inProgress) {
      return;
    }
    this.inProgress = true;

    this.cleanupUrlAfterAutoLike();

    this.liked = !this.liked;

    if (this.liked) {
      this.likesCount++;

      const response = await window.axios.post(this.apiUrl);

      if (response.status === 201) {
        this.$notify.success(bergfex.ratingLike.success.add, '');
        tagmanager.event('tour-detail_rating_like', {});
      } else {
        this.likesCount--;
        this.$notify.error(bergfex.error.headline, bergfex.error.text);
      }
    } else {
      this.likesCount--;
      const response = await window.axios.delete(this.apiUrl);

      if (response.status === 204) {
        this.$notify.success(bergfex.ratingLike.success.remove, '');
        tagmanager.event('tour-detail_rating_unlike', {});
      } else {
        this.likesCount++;
        this.$notify.error(bergfex.error.headline, bergfex.error.text);
      }
    }

    this.inProgress = false;

    this.$dispatch('helpful-toggled', {
      id: this.id,
      liked: this.liked,
      likesCount: this.likesCount,
    });
  },
  handleHelpfulToggled(event) {
    if (event.detail.id === this.id) {
      this.liked = event.detail.liked;
      this.likesCount = event.detail.likesCount;
    }
  },
  cleanupUrlAfterAutoLike() {
    const paramToRemove = 'likeRating';
    if (new URLSearchParams(window.location.search).has(paramToRemove)) {
      const url = new URL(window.location);
      url.searchParams.delete(paramToRemove);
      history.replaceState(null, '', url.toString());
    }
  },
});

export default userRatingLike;
