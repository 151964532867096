import tagmanager from '../../abstracts/tagmanager.js';

const report = (url, email, successTitle, successMessage) => ({
  form: {},
  step: 1,
  reasonText: '',

  init() {
    this.form = this.$form('post', url, {
      email: email ?? '',
      reason: '',
      note: '',
    });
  },

  submit(tracking = false) {
    this.form
      .submit()
      .then(() => {
        this.form.reset();

        if (tracking) {
          tagmanager.event(tracking, {});
        }

        this.$alert.show('icon-check', successTitle, successMessage);

        this.step = 1;
      })
      .catch((error) => {
        if (error.response?.status !== 422) {
          this.$alert.show('icon-warnings', bergfex.error.headline, bergfex.error.text, () =>
            window.location.reload(),
          );
        }
      });
  },
});

export default report;
