import { BREAKPOINT_SM } from '../../abstracts/responsive';
import { getCookie, setCookie } from '../../abstracts/cookie';

const stickyBanner = (
  cookieName = 'vombfa',
  conditionCallback = (value) => value === 1,
  showAlwaysOnDesktop = false,
) => ({
  showBanner: false,
  loaded: false,
  init() {
    if (showAlwaysOnDesktop && window.innerWidth <= BREAKPOINT_SM && this.checkCookieCondition()) {
      return;
    }

    if (
      !showAlwaysOnDesktop &&
      (window.innerWidth > BREAKPOINT_SM || this.checkCookieCondition())
    ) {
      return; // show only on mobile && do not show if cookie is set
    }

    this.$dispatch('loaded');

    document.addEventListener('scroll', this.scroll.bind(this));
  },

  close() {
    this.$root.remove();
    document.removeEventListener('scroll', this.scroll);
    setCookie(cookieName, 1, 7);
  },

  scroll() {
    this.showBanner = window.scrollY > 0;

    if (!this.loaded && this.showBanner) {
      this.loaded = true;
      this.$dispatch('loaded');
    }
  },

  checkCookieCondition() {
    const cookieValue = parseInt(getCookie(cookieName));
    return conditionCallback(cookieValue);
  },
});

export default stickyBanner;
