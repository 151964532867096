const event = (name, data) => {
  bergfex.events.add(name, data);
};

const gtag = function () {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
};

const consent = (type, consents, callback = null) => {
  gtag('consent', type, consents);

  callback?.();

  if (consents.analytics_storage === 'granted') {
    bergfex.events.init = true;
    bergfex.events.handle();
  }
};

const content = (level, group, type) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'content-level': level,
    'content-group': group,
    'content-type': type,
  });
};

export const init = () => {
  consent('default', {
    ad_storage: 'denied',
    analytics_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    functionality_storage: 'granted',
  });

  bergfex.content = content;
  bergfex.gtag = gtag;
  bergfex.gtagEvent = function (name, category, prefix, suffix) {
    const eventPrefix = prefix ? prefix : '';
    const eventSuffix = suffix ? suffix : '_lang';

    event(name, {
      event_category: category,
      event_label: eventPrefix + '-' + eventSuffix,
    });
  };

  bergfex.gtagEventWithData = function (name, data) {
    event(name, data);
  };
};

bergfex.events = {
  init: false,
  queue: [],
  running: false,
  add(name, data) {
    if (this.init) {
      this.send(name, data);
      return;
    }
    this.queue.push({ name: name, data: data });
  },
  handle() {
    if (this.running || !this.init) {
      return;
    }

    this.running = true;
    this.queue.forEach((event) => {
      this.send(event.name, event.data);
    });

    this.queue = [];
    this.running = false;
  },
  send(name, data) {
    bergfex.gtag('event', name, data);
  },
};

export default { event, consent, gtag };
